import React from "react";

const index = () => {
  return (
    <div className="flex md:flex-row flex-col justify-center md:my-20 md:px-40 items-center">
      <div className="flex justify-center mx-3 md:mx-3 items-baseline">
        
        <img
          src={require("./../../assets/image/award.png")}
          alt="awardimg"
          // className="w-3/4"
        />

        
      </div>
      <div className="md:w-7/12 md:pl-24">
        <h1 className="text-titleColor md:text-6xl flex md:block text-4xl justify-center font-bold">Award Winning</h1>
        <h3 className="text-white md:text-5xl text-xl flex md:block justify-center mt-3">App Development Company</h3>
        <h3 className="text-white md:text-2xl text-xl  mx-5 md:mx-0 mt-5 text-center md:text-start">
          <span className="text-titleColor">#1 Development Agency </span>awarded
          for its{" "}
          <span className="text-titleColor"> Top Featured/Editors </span>
          Apps Delivered.{" "}
        </h3>
        <p className="text-white mx-5 my-4 md:my-0 md:mx-0 mt-7 text-center md:text-start">
        Tycho is the one of the top-rated TV apps development companies in Delhi/NCR. We strongly believe that modern businesses can benefit immensely from a smart TV app, especially the businesses eager to explore new avenues to connect with their potential customers and smart TV users. With smart TV apps, businesses can avail multiple opportunities for catering to the dynamic needs of binge-watchers through world-class, one-stop smart TV solution.
        </p>
      </div>
    </div>
  );
};

export default index;
