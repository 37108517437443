import React from "react";

const index = () => {
  return (
    <div className="flex flex-col-reverse md:flex-row justify-around mt-20 md:px-52 items-center mb-10">
    <div className=" md:pr-10 md:pl-24">
        <div className="flex justify-center items-baseline md:mx-5">
          <img src={require("./../../assets/image/ahaimg.png")} alt="homeimg"/>
        </div>
      </div>
      <div className="md:w-7/12  md:mr-20">
        <div className="flex md:flex-row flex-col md:justify-start items-center">
          <img src={require("./../../assets/image/ahaLogo.png")} alt="ahalogoimg"/>
          <h1 className="text-red md:text-6xl text-4xl text-hoichoi ml-6 underline font-semibold text-center">
            aha
          </h1>
        </div>
        <p className="text-white mt-7 mx-5 md:mx-0 text-center md:text-start">
        aha is an Indian subscription video on-demand and over-the-top streaming service which offers Telugu and Tamil-language content. It is owned by Arha Media & Broadcasting Private Limited, a joint venture by Geetha Arts and My Home Group
        </p>
        <div className="bg-white flex md:flex-row flex-col justify-between items-center rounded mt-8 mx-10 md:mx-0">
          <div className="bg-white  flex justify-between items-center flex-col pt-5 pb-4 md:border-r md:w-1/3">
            <div>
              <img src={require("./../../assets/image/download.png")} alt="downloadimg"/>
            </div>
            <div className="flex  items-center flex-col">
              <h2 className="font-bold text-3xl mt-3 ">1cr+</h2>
              <h4 className="text-sm font-semibold ">Downloads</h4>
            </div>
          </div>
          <div className="bg-white   flex justify-between items-center flex-col pt-5 pb-4 md:border-r md:w-1/3">
            <div>
              <img src={require("./../../assets/image/subscribe.png")} alt="subscribeimg"/>
            </div>
            <div className="flex  items-center flex-col">
              <h2 className="font-bold text-3xl mt-3">50L+</h2>
              <h4 className="text-sm font-semibold ">Subscribers</h4>
            </div>
          </div>
          <div className="bg-white  flex justify-between items-center flex-col pt-5 pb-4 md:border-r md:w-1/3">
            <div>
              <img src={require("./../../assets/image/rate.png")} alt="rateimg"/>
            </div>
            <div className="flex  items-center flex-col">
              <h2 className="font-bold text-3xl mt-3">4.3</h2>
              <h4 className="text-sm font-semibold">Ratings</h4>
            </div>
          </div>
        </div>
        <div className="flex md:flex-row flex-col md:justify-around items-center my-10">
            <a href="https://play.google.com/store/apps/details?id=ahaflix.tv" target="_blank" rel="noreferrer"><img src={require("./../../assets/image/googlePlay.png")} alt="googleplayimg"/></a>
            <a href="https://apps.apple.com/in/app/aha-ott-movies-webseries/id1488739001" target="_blank" rel="noreferrer"><img src={require("./../../assets/image/appStore.png")} alt="appstoreimg" className="mt-5 md:mt-0"/></a>
        </div>
      </div>
      
    </div>
  );
};

export default index;
