import React from "react";

const GetStarted = () => {
  return (
    <div className="flex md:flex-row flex-col md:justify-center md:mt-20 md:px-40 md:items-center">
      <div className="md:w-7/12 md:pl-24">
        <h1 className="text-white text-4xl flex md:block justify-center md:text-6xl">
          Top <span className="text-titleColor font-semibold pl-2 md:pl-0">OTT App</span>
        </h1>
        <h3 className="text-white md:text-4xl flex md:block justify-center mt-3">Development Company in</h3>
        <h3 className="text-white text-4xl flex md:block justify-center mt-3">India</h3>
        <p className="text-white mt-7 mx-5 md:mx-0 text-center md:text-start">
        Count on us for delivering the ultimate on-demand streaming experience on smart TVs and other smart devices with our custom solutions. Hire TV apps developers in India to build web applications, gaming apps, entertainment TV apps, security apps, smart TV apps, and multimedia apps.
        </p>
        <a href="#form-section"><button className="bg-white mt-7 mx-auto md:mx-0 p-3 rounded flex justify-between items-center">
        Get Started <img src={require("./../../assets/image/arrowBlack.png")} alt="arrowimg" className="w-5 ml-3" />
        </button></a>
      </div>
      <div className="md:mr-20 mx-5 mt-5">
        <div className="md:flex md:justify-center md:items-baseline">
          {/* <img
            src={require("./../../assets/image/home-imgTab.png")}
            className="relative left-40 top-5 w-3\/4"
          /> */}
          <img
            src={require("./../../assets/image/getStartedImg.png")} 
            alt="commonimg"
            // className="w-3/4"
          />

          {/* <img
            src={require("./../../assets/image/home-imgPhone.png")}
            className="relative right-16 top-5 w-3\/4"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
