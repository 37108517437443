import React from "react";

const index = () => {

  const mailtoHref = "mailto: contact@tychotechnologies.in?subject=SendMail&body=Description";
  const contacttoHref = "tel: +919650982383";
  return (
    <>
      <div className="flex  justify-between  bg-red items-center content-center  h-20 mb-10 md:hidden ">
        <div >
          <img src={require("./../../assets/image/Tycho_Logo.png")} alt="logo" width="110px" height="110px" className="ml-3 mt-3"/>
        </div>
        
        <div className="flex flex-row-reverse items-center">
        <a href = {mailtoHref} target="_blank" rel="noreferrer">
        <div className="flex mt-3 mx-3">
          <img
            src={require("./../../assets/image/mail-icon.png")}
            alt="email-logo"
            
          />
          <span className="text-white mr-0   ml-2  hidden ">
            contact@tychotechnologies.in 
          </span>
          </div>
          </a>
          <a href={contacttoHref} >
          <div className="flex mt-3 ml-3">
          <img src={require("./../../assets/image/telephone.png")} alt="telephoneicon"/>
          <div className="text-white ml-2 hidden"> +919650982383</div>
          </div>
          </a>
          <button className="py-3 mt-3 px-5 bg-white text-black rounded-lg text-sm font-semibold">
          <a href="#form-section"> Request Quote </a>
          </button>
        </div>
      </div>
    </>
  );
};

export default index;
