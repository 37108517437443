import React from "react";

const index = () => {
  return (
    <div className=" flex flex-col md:justify-center md:items-center md:px-52 py-8 mx-5 text-center">
      <h1 className="text-white text-4xl mt-3 font-bold">
        We Have Delivered <span className="text-titleColor">400+</span> Apps
        Globally
      </h1>
      <p className="text-white text-base mt-6">
      Since inception, we've been delivering innovative, unique, and outstanding quality end-to-end web and mobile solution to global clientele. Happy clientele and dedicated & competent workforce are The foundation stones of Tycho and each project adds to our accomplishments.
      </p>
    </div>
  );
};

export default index;
