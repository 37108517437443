import React from "react";

const index = () => {
  return (
    <div className="flex md:flex-row flex-col justify-around mt-20 md:px-52 md:items-center">
      <div className="md:w-7/12 md:pl-24 md:pr-10">
        <div className="flex flex-col md:flex-row justify-start items-center">
          <img src={require("./../../assets/image/hoichoi.png")} alt="hoichoilogo"/>
          <h1 className="text-red text-4xl md:text-6xl text-hoichoi md:ml-6 underline font-semibold">
            HOICHOI
          </h1>
        </div>
        <p className="text-white mt-7 text-center md:text-start">
          Hoichoi is an on-demand video streaming platform for Bengalis all over
          the World. Subscribe now to get access to 600+ Bengali Movies, 100+
          Original Web Series,TV Shows & Films with new Bengali content added
          every month.
        </p>
        <div className="bg-white flex md:flex-row flex-col md:justify-between items-center rounded mt-8 mx-10 md:mx-0">
          <div className="bg-white flex justify-between items-center flex-col pt-5 pb-4 md:border-r md:w-1/3">
            <div>
              <img src={require("./../../assets/image/download.png")} alt="downloadimg"/>
            </div>
            <div className="flex  items-center flex-col">
              <h2 className="font-bold text-3xl mt-3 ">50L+</h2>
              <h4 className="text-sm font-semibold ">Downloads</h4>
            </div>
          </div>
          <div className="bg-white flex justify-between items-center flex-col pt-5 pb-4 md:border-r md:w-1/3">
            <div>
              <img src={require("./../../assets/image/subscribe.png")} alt="subscribeimg"/>
            </div>
            <div className="flex  items-center flex-col">
              <h2 className="font-bold text-3xl mt-3">50L+</h2>
              <h4 className="text-sm font-semibold ">Subscribers</h4>
            </div>
          </div>
          <div className="bg-white  flex justify-between items-center flex-col pt-5 pb-4 md:border-r md:w-1/3">
            <div>
              <img src={require("./../../assets/image/rate.png")} alt="rateimg"/>
            </div>
            <div className="flex  items-center flex-col">
              <h2 className="font-bold text-3xl mt-3">4.0</h2>
              <h4 className="text-sm font-semibold">Ratings</h4>
            </div>
          </div>
        </div>
        <div className="flex md:flex-row flex-col mt-5 justify-around items-center md:mt-8">
            <a href="https://play.google.com/store/apps/details?id=com.viewlift.hoichoi" target="_blank" rel="noreferrer"><img src={require("./../../assets/image/googlePlay.png")} alt="googleplayimg" className/></a>
            <a href="https://apps.apple.com/us/app/hoichoi-bengali-movies/id1188755540?ls=1" target="_blank" rel="noreferrer"><img src={require("./../../assets/image/appStore.png")} alt="appstoreimg" className="mt-5 md:mt-0"/></a>
        </div>
      </div>
      <div className="md:mr-20 mt-10 mx-5 md:mx-0 md:mt-0">
        <div className="flex justify-center items-baseline">
          <img src={require("./../../assets/image/hoichoibg.png")} alt="commonimg"/>
        </div>
      </div>
    </div>
  );
};

export default index;
