import React from "react";

const index = () => {
  return (
    <div className="flex md:flex-row flex-col justify-center items-center mt-36">
      
        <img src={require("../../assets/image/aha.png")} alt="ahaimg" className="md:w-2/5 z-30 relative bottom-28 md:left-36" />
      
      <div className="bg-bgGreen  md:w-1/3 md:h-96 py-9 md:px-12 rounded mx-5 md:mx-0 flex flex-row justify-start md:mr-32">
        <div className="md:w-2/3">{""}</div>
        <div className="flex flex-col justify-center items-start">
          <h1 className="md:text-5xl text-xl mx-auto md:mx-0 font-bold ">Startups and SMEs</h1>
          <p className="text-paraColor mx-5 md:mx-0 text-base mt-6">
          We are ready and enthusiastic to help you make the most of your digital presence. Call us or write to us for a candid discussion about reshaping your web identity and providing it a competitive advantage.
          </p>
          <a href="#form-section"><button className="bg-black mt-7 p-3 rounded flex mx-auto md:mx-0 justify-between items-center text-white">
          Get Started{" "}
            <img
              src={require("./../../assets/image/arrowWhite.png")}
              alt="arrowimg"
              className="md:w-5 ml-3"
            />
          </button></a>
        </div>
      </div>
    </div>
  );
};

export default index;
