import React from "react";

const index = () => {
  return (
    <div className="flex lg:space-y-0 space-y-4 space-x-0 lg:space-x-4  lg:flex-row flex-col justify-around items-center my-40  md:px-72">
      <div className="bg-white w-64 h-64 rounded-xl flex justify-between items-center flex-col pt-10 pb-4">
        <div>
          <img src={require("./../../assets/image/group.png")} alt="groupimg"/>
        </div>
        <div className="flex  items-center flex-col">
          <h2 className="font-bold text-4xl ">50+</h2>
          <h4 className="text-2xl ">Fit Attendant</h4>
        </div>
      </div>
      <div className="bg-white w-64 h-64 rounded-xl flex justify-between items-center flex-col pt-10 pb-4">
        <div>
          <img src={require("./../../assets/image/group.png")} alt="groupimg"/>
        </div>
        <div className="flex  items-center flex-col">
          <h2 className="font-bold text-4xl ">400+</h2>
          <h4 className="text-2xl ">Projects Delivered</h4>
        </div>
      </div>
      <div className="bg-white w-64 h-64 rounded-xl flex justify-between items-center flex-col pt-10 pb-4">
        <div>
          <img src={require("./../../assets/image/group.png")} alt="groupimg"/>
        </div>
        <div className="flex  items-center flex-col">
          <h2 className="font-bold text-4xl ">10+</h2>
          <h4 className="text-2xl ">Countries Tuck</h4>
        </div>
      </div>
    </div>
  );
};

export default index;
