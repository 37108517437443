import React from "react";
import Header from "../components/Header";
import GetStarted from "./Getstarted";
import CardSec from "./CardSec";
// import FeaturedSec from "./FeaturedSec";
import Award from "./Award";
import WantToGrow from "./WantToGrow";
import Startup from "./Startup";
import WeDeliver from "./WeDeliver";
import Hoichoi from "./Hoichoi";
import Aha from "./Aha";
import Footer from "./Footer";
import FormSection from "./FormSection"
import Mobileheader from "./Mobile-header"

const Index = () => {
  return (
    <div className="bg-bgColorMain">
      <Header/>
      <Mobileheader/>
      <GetStarted/>
      <CardSec/>
      {/* <FeaturedSec/> */}
      <Award/>
      <WantToGrow/>
      <Startup/>
      <WeDeliver/>
      <Hoichoi/>
      <Aha/>
      <FormSection/>
      <Footer/>
    </div>
  );
};

export default Index;
