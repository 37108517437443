import React from "react";

const index = () => {

  const mailtoHref = "mailto: contact@tychotechnologies.in?subject=SendMail&body=Description";
  const contacttoHref = "tel: +919650982383";
  return (
    <>
      <div className="hidden md:flex md:flex-row md:justify-around justify-between md:h-20 bg-red items-center content-center md:py-10 h-20 mb-10">
        <div >
          <img src={require("./../../assets/image/Tycho_Logo.png")} alt="logo" width="200px" height="200px"/>
        </div>
        
        <div className="flex flex-row-reverse md:flex-row items-center">
        <a href = {mailtoHref} target="_blank" rel="noreferrer">
        <div className="flex mt-3 md:mt-0">
          <img
            src={require("./../../assets/image/mail-icon.png")}
            alt="email-logo"
            
          />
          <span className="text-white mr-0 md:border-r-2 md:pr-4 ml-2 md:mr-4 hidden md:block">
            contact@tychotechnologies.in 
          </span>
          </div>
          </a>
          <a href={contacttoHref}>
          <div className="flex mt-3 md:mt-0 mx-3">
          <img src={require("./../../assets/image/telephone.png")} alt="telephoneicon"/>
          <div className="text-white ml-2 hidden md:block"> +919650982383</div>
          </div>
          </a>
          <a href="#form-section"><button className="py-3 mt-3 md:mt-0 px-5 bg-white text-black rounded-lg md:mx-6 text-sm md:text-lg font-semibold" >
            Request Quote 
          </button></a>
        </div>
      </div>
    </>
  );
};

export default index;
