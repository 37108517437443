import React from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

const Index = () => {


  const params = new URLSearchParams(window.location.search);
  const paramsEmail = params.get('email');
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstname: '',
      lastname: '',
      contact: "",
      email: paramsEmail,
      checkbox: [],
      description: ''
    }
  });

  
  const onSubmit = async (value,e) => {
    console.log(value.checkbox.join(","));
    console.log('value',value);
    try {
      const res = await axios.post(
        "http://tychotechnologies-sales.com/sendContactDetails.php",
        {
          firstName: value.firstname,
          lastName: value.lastname,
          email: paramsEmail,
          phone: value.contact,
          serviceRequired: value.checkbox.join(","),
          message: value.description,
        }
      );
      
       if(res){
        alert("Data posted successfully!")
        reset({
          firstname: '',
          lastname: '',
          contact: "",
          email: paramsEmail,
          checkbox: [],
          description: ''
        })
       }
      
    } catch (error) {
      alert("There is an error!...");
      console.log(error);
    }
  };
  return (
    <div className="py-16 bg-formsection static md:relative md:h-100" id="form-section">
      <h1 className="text-booktext md:text-4xl text-2xl mb-5 md:mb-5 mx-auto flex justify-center">
        Book A Free Consultation
      </h1>

      <div className="flex justify-center">
        <form>
          <div className="flex flex-col w-full md:w-full justify-items-center bg-form md:px-12 pt-5 rounded-md static md:relative md:top-10 md:h-90">
            <div className="mx-5 mb-5">
              <div className="flex md:mx-5 flex-col md:flex-row">
                <div className="flex flex-col md:mr-5">
                  <label className="text-slate-500 mb-1 md:mb-0 md:ml-2 ">
                    First Name
                  </label>
                  <input
                    placeholder="First Name"
                    className="py-3 pl-2 rounded-md md:w-80 border-solid outline-none"
                    {...register("firstname", { required: true })}
                    require
                  />
                  <p className="h-5 text-sm" style={{color: "red"}}>{errors.firstname?.type === "required" ?
                      "* First name is required" : ""
                  }</p>
                  
                </div>
                <div className="flex flex-col ">
                  <label className="text-slate-500 mb-1 md:mb-0 md:ml-2">
                    Last Name
                  </label>
                  <input
                    placeholder="Last Name"
                    className="py-3 pl-2 rounded-md md:w-80 border-solid border-slate-400 outline-none"
                    {...register("lastname", { required: true })}
                  />
                  <p className="h-5 text-sm" style={{color: "red"}}>{errors.lastname?.type === "required" ?
                  "* Last name is required" : ""
              }</p>
                </div>
              </div>
              <div className="flex md:mx-5 flex-col md:flex-row">
                <div className="flex flex-col md:mr-5">
                  <label className="text-slate-500 mb-1 md:mb-0 md:ml-2">Email</label>
                  <input
                    placeholder="Email Address"
                    className="py-3 pl-2 rounded-md md:w-80 border-solid border-slate-400 outline-none"
                    {...register("email", { required: true,pattern: { value:  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,message: "* email is not valid."} })}
                  />
                  <p className="h-5 text-sm" style={{color: "red"}}>{errors.email?.type === "required" ?
                  "* Email is required" : ""
              }  {errors.email?.message }</p>
                </div>
                <div className="flex flex-col">
                  <label className="text-slate-500 mb-1 md:mb-0 md:ml-2">Contact</label>
                  <input
                    placeholder="Contact Number"
                    className="py-3 pl-2 rounded-md md:w-80 border-solid border-slate-400 outline-none"
                    {...register("contact", { required: true,pattern: {value: /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,message: "* Contact number not valid"}})}
                  />
                  <p className="h-5 text-sm" style={{color: "red"}}>{errors.contact?.type === "required" ?
                  "* Contact is required" : ""
              } {errors.contact?.message }</p>
                </div>
              </div>
            </div>
            <div className="md:flex md:justify-start md:flex-row pl-6 mr-5">
              <label className="text-slate-500 md:ml-5">Services</label>
              <div className="md:flex md:flex-col md:ml-14">
                <div className="flex flex-col md:flex-row md:mb-3">
                  <div>
                    <input
                      type="checkbox"
                      {...register("checkbox")}
                      value="Custom OTT App Development"
                    />{" "}
                    <label className="">
                      Custom OTT App Development
                    </label>
                  </div>
                  <div className="md:pl-19">
                    <input
                      type="checkbox"
                      {...register("checkbox")}
                      value="OTT App UI/UX Design"
                    />{" "}
                    <label>OTT App UI/UX Design</label>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row md:mb-3">
                  <div>
                    <input
                      type="checkbox"
                      {...register("checkbox")}
                      value="OTT Plateform Integration"
                    />{" "}
                    <label className="">OTT Plateform Integration</label>
                  </div>
                  <div className="md:pl-29">
                    <input
                      type="checkbox"
                      {...register("checkbox")}
                      value="OTT App Idea & Content Creation"
                      className=""
                    />{" "}
                    <label>OTT App Idea & Content Creation</label>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row md:mb-3">
                  <div>
                    <input
                      type="checkbox"
                      {...register("checkbox")}
                      value="OTT App Support & Testing"
                    />{" "}
                    <label className="md:mr-5">OTT App Support & Testing</label>
                  </div>
                  <div className="md:pl-39" >
                    <input
                      type="checkbox"
                      {...register("checkbox")}
                      value="OTT Maintenence & Updates"
                      className=""
                    />{" "}
                    <label>OTT Maintenence & Updates</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-start md:ml-6">
              <label className="text-slate-500 mt-5 md:mt-12 ml-5 mb-3">
                About Project
              </label>
              <div className="md:w-3/4 md:ml-5 mx-5">
                <textarea
                  className="w-full h-32 resize-none pt-3 pl-3 border-solid rounded-lg border-black outline-none"
                  {...register("description")}
                  placeholder="Description"
                ></textarea>
                
              </div>
            </div>
            <div className="flex justify-center">
              <button
                className="bg-black rounded-md my-5 text-white px-3 py-5"
                onClick={handleSubmit(onSubmit)}
              >
                <span className="flex">
                  Get Started
                  <img
                    src={require("./../../assets/image/Arrow.png")}
                    alt="arrow"
                    className="pl-3"
                  />
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Index;
