import React from "react";
import FacebookSvg from "./../../assets/image/facebooksvg.svg";
import TwitterSvg from "./../../assets/image/twitter.svg";
import LinkdinSvg from "./../../assets/image/linkdin.svg";
// import InstagramSvg from "./../../assets/image/instagram.svg";

const index = () => {
  return (
    <div className="pt-10 md:pt-24 pb-8 bg-footer p">
      <div className="flex flex-col mx-5 md:flex-row justify-around py-12">
        <div>
          <img src={require("./../../assets/image/Tycho_Logo.png")} alt="logopic" width="150px" height="150px"/>
          <div className="text-white">H-182 ,GF, Sector 63, Noida</div>
          <div className="text-white">Ghaziabad Uttar Pradesh, India</div>
        </div>
        <div className="flex flex-col md:justify-items-center ">
          <div className="text-white font-normal md:m-auto">Follow us</div>
          <div className="flex mt-2">
            <a href="https://www.facebook.com/tychotechnologiespvtltd" target="_blank" rel="noreferrer"><img src={FacebookSvg} alt="facebook-logo" className="mx-2"/></a>
            <a href="https://twitter.com/TychoTech" target="_blank" rel="noreferrer"><img src={TwitterSvg} alt="twitter-logo" className="mx-2" /></a>
            <a href="https://in.linkedin.com/company/tycho-technologies-pvt-ltd?original_referer=https%3A%2F%2Fwww.tychotechnologies.com%2F" target="_blank" rel="noreferrer"><img src={LinkdinSvg} alt="linkdin-logo" className="mx-2" /></a>
          </div>
        </div>
      </div>
      <div>
        <h2 className="text-white mx-10 md:mx-0 font-normal text-sm flex justify-center">
          Copyright©2022 & Designed & Developed By: Tycho Technologies
        </h2>
      </div>
    </div>
  );
};

export default index;
