import React from "react";

const index = () => {
  return (
    <div className="bg-bgColor1 flex flex-col justify-center mx-5 rounded-md md:mx-0 items-center md:px-52 py-8 text-center">
      <h1 className="text-black text-4xl mx-3 md:mx-0 mt-3 font-bold">
        Want to Grow Your Business Globally ?
      </h1>
      <p className="text-paraColor mx-3 md:mx-0 text-base mt-6">
      Tycho Technologies delivers the most innovative and distinctive web, mobile and ott services all over the world. We offer a wide range of services in web and mobile app development and our core values includes in delivering quality as always being a priority. The deep expertise and dedication of our team meet all challenges and proffers exceptional services to our global clientele.
      </p>
      <a href="#form-section"><button className="bg-black mt-7 p-3 rounded flex justify-between items-center text-white">
      Get Started{" "}
        <img
          src={require("./../../assets/image/arrowWhite.png")}
          alt="arrowimg"
          className="md:w-5 ml-3"
        />
      </button></a>
    </div>
  );
};

export default index;
